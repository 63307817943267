.vc_row[data-vc-full-width]{
  opacity: 1 !important;
}
nav.pagination{
  a{
    cursor: pointer;
  }
}

.movie-details {
  .btn{
    margin-right: 0.5em;
  }
  .btn-watched {
    &:after {
      content: '\f058';
      font-family: 'fontawesome';
      margin-left: 1em;
    }
  }
}

.general-search{
  button{
    width: 100%
  }
  select{
    border-radius: 0;
  }
}