@font-face {
  font-family: "fontawesome";
  font-weight: normal;
  font-style : normal;
  src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
  src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0") format("embedded-opentype"),
  url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0") format("woff2"),
  url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0") format("woff"),
  url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0") format("truetype"),
  url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular") format("svg");
}

@font-face {
  font-family: 'themify';
  src:url('https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.0/fonts/themify.eot?-fvbane');
  src:url('https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.0/fonts/themify.eot?#iefix-fvbane') format('embedded-opentype'),
  url('https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.0/fonts/themify.woff?-fvbane') format('woff'),
  url('https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.0/fonts/themify.ttf?-fvbane') format('truetype'),
  url('https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.0/fonts/themify.svg?-fvbane#themify') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
